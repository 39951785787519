import { Typography } from '@genialcare/atipico-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { REQUIRED_ERROR_MESSAGE as ERROR_MESSAGE } from 'constants/validation-messages';
import { buildMinLengthValidationMessage } from 'utils/validation';
import { TextAreaErrorMessage } from 'components/Form/TextArea';
import * as S from './ParentalTrainingForm.styles';
import { GoalsListInput } from '../GoalsListInput';

type ParentalTrainingFormProps = {
  showInputGoals?: boolean;
};

export const ParentalTrainingForm: React.FC<ParentalTrainingFormProps> = ({
  showInputGoals = false,
}) => {
  const { register, formState } = useFormContext();
  const errors = formState.errors;

  return (
    <>
      <S.FieldGroup>
        {showInputGoals && (
          <>
            <Typography variant="body2" weight="bold">
              1 - Selecione o(s) objetivo(s) da Orientação Parental.*
            </Typography>

            <GoalsListInput />
          </>
        )}

        {!showInputGoals && (
          <>
            <Typography variant="body2" weight="bold">
              1 - Descreva os objetivos da sessão.*
            </Typography>

            <S.HelperText variant="body2">
              Ex: Trabalhar com escova elétrica, verificar se da para trabalhar o escovar os dentes
              depois do lanchinho, conteúdo de estratégias de apoio e alimentação, momentos de tv e
              etc.
            </S.HelperText>

            <S.TextArea
              data-testid="session-goals-input"
              placeholder="Descreva aqui"
              isInvalid={!!errors.sessionGoals}
              {...register('sessionGoals', {
                required: ERROR_MESSAGE,
                minLength: buildMinLengthValidationMessage(5),
              })}
            />

            {errors.sessionGoals && (
              <TextAreaErrorMessage>{errors.sessionGoals.message?.toString()}</TextAreaErrorMessage>
            )}
          </>
        )}
      </S.FieldGroup>
      <S.FieldGroup>
        <Typography variant="body2" weight="bold">
          2 - Descreva as anotações relevantes sobre a sessão.*
        </Typography>
        <S.HelperText variant="body2">
          Ex: Alimentação é uma preocupação. Tem comido bem na escola, queria colocar todas as balas
          na boca, começou com algumas rigidez, querendo que a perna fique reta, tem pedido comidas
          e comido e etc.
        </S.HelperText>
        <S.TextArea
          data-testid="body-input"
          placeholder="Descreva aqui"
          isInvalid={!!errors.body}
          {...register('body', {
            required: ERROR_MESSAGE,
            minLength: buildMinLengthValidationMessage(5),
          })}
        />
        {errors.body && (
          <TextAreaErrorMessage>{errors.body.message?.toString()}</TextAreaErrorMessage>
        )}
      </S.FieldGroup>
      <S.FieldGroup>
        <Typography variant="body2" weight="bold">
          3 - Caso exista, escreva os combinados para a próxima sessão.
        </Typography>
        <S.HelperText variant="body2">
          Ex: Conteúdo de estratégias de apoio, alimentação, trazer mais situações para discussão e
          etc.
        </S.HelperText>
        <S.TextArea
          data-testid="next-session-agreements-input"
          placeholder="Descreva aqui"
          isInvalid={!!errors.nextSessionAgreements}
          {...register('nextSessionAgreements', {
            required: false,
          })}
        />
      </S.FieldGroup>
    </>
  );
};
