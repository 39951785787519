import { useState, useEffect, useCallback } from 'react';
import { useLazyQuery, LazyQueryHookOptions } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { GET_CLINICAL_GUIDANCE } from 'queries';
import { ClinicalGuidance } from 'types';
import { useUpdateClinicalGuidance } from 'hooks/useUpdateClinicalGuidance';
import { useCreateClinicalGuidanceComment } from 'hooks/useCreateClinicalGuidanceComment';

type Data = {
  clinicalGuidance: ClinicalGuidance;
};

type Variables = {
  id: string;
};

export const useModal = (options?: LazyQueryHookOptions<Data, Variables>) => {
  const [open, setOpen] = useState(false);
  const [clinicalGuidanceId, setClinicalGuidanceId] = useState('');
  const { getAccessTokenSilently } = useAuth0();
  const {
    mutation: createClinicalGuidanceComment,
    data: createClinicalGuidanceCommentData,
    ...createClinicalGuidanceCommentState
  } = useCreateClinicalGuidanceComment();
  const {
    mutation: updateClinicalGuidance,
    data: updateClinicalGuidanceData,
    ...updateClinicalGuidanceState
  } = useUpdateClinicalGuidance();
  const [query, { data, loading, error, updateQuery }] = useLazyQuery<Data, Variables>(
    GET_CLINICAL_GUIDANCE,
    options,
  );

  const handleGetClinicalGuidance = (id: string) => {
    setOpen(true);
    setClinicalGuidanceId(id);
  };

  const fetchGetClinicalGuidance = useCallback(async () => {
    const accessToken = await getAccessTokenSilently();

    query({
      variables: { id: clinicalGuidanceId },
      context: {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      },
    });
  }, [getAccessTokenSilently, query, clinicalGuidanceId]);

  useEffect(() => {
    if (!open) {
      setClinicalGuidanceId('');
    }
  }, [open]);

  useEffect(() => {
    if (open && clinicalGuidanceId) {
      fetchGetClinicalGuidance();
    }
  }, [open, clinicalGuidanceId]);

  useEffect(() => {
    if (updateClinicalGuidanceData) {
      updateQuery(() => ({
        clinicalGuidance: { ...updateClinicalGuidanceData },
      }));
    }
  }, [updateQuery, updateClinicalGuidanceData]);

  useEffect(() => {
    if (createClinicalGuidanceCommentData) {
      updateQuery(({ clinicalGuidance }) => ({
        clinicalGuidance: {
          ...clinicalGuidance,
          comments: [...(clinicalGuidance?.comments ?? []), createClinicalGuidanceCommentData],
        },
      }));
    }
  }, [updateQuery, createClinicalGuidanceCommentData]);

  return {
    data,
    open,
    error,
    loading,
    updateQuery,
    handleGetClinicalGuidance,
    updateClinicalGuidance,
    createClinicalGuidanceComment,
    handleOpenModal: setOpen,
    updateClinicalGuidanceState: {
      data: updateClinicalGuidanceData,
      ...updateClinicalGuidanceState,
    },
    createClinicalGuidanceCommentState: { ...createClinicalGuidanceCommentState },
  };
};

export type ModalHookPropsReturn = ReturnType<typeof useModal>;
