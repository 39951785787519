import React from 'react';
import * as S from './Checkbox.styles';

type RadioErrorMessageProps = {
  children: React.ReactNode | React.ReactNode[];
};

export const CheckboxErrorMessage = React.forwardRef<HTMLSpanElement, RadioErrorMessageProps>(
  ({ children }, ref) => {
    return <S.TextErrorMessageRoot ref={ref}>{children}</S.TextErrorMessageRoot>;
  },
);
