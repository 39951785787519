import { TextAreaErrorMessage } from 'components/Form/TextArea';
import React, { useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import * as S from './GoalsListInput.styles';
import { Checkbox } from 'components/Form';

const GOALS_LIST = [
  'Comportamentos Desafiadores e Regulação Emocional',
  'Dificuldades com Rotina, Alimentação e Autonomia',
  'Atividades de vida diária',
  'Comunicação e CAA',
  'Desafios em Contextos Familiares e Escolares',
  'Saúde e Mudanças no Tratamento Médico',
  'Segurança física e Proteção',
  'Alinhamento e Ajustes na Intervenção',
  'Intervenções para Socialização e Integração Escolar',
  'Acolhimento e Orientações para Saúde Mental do Cuidador',
  'Integração Sensorial e Comportamentos Sensoriais',
  'Aspectos da fala e motricidade orofacial',
];

type GoalsListInputProps = {
  goalsList?: string[];
};

export const GoalsListInput: React.FC<GoalsListInputProps> = ({ goalsList = GOALS_LIST }) => {
  const { setValue, trigger, getValues, formState, control } = useFormContext();
  const errors = formState.errors;
  const initialGoals = getValues('goals') || [];
  const [selectedGoals, setSelectedGoals] = useState(initialGoals);

  const handleSelectGoal = (goal: string) => () => {
    const goals = selectedGoals || [];

    let newGoals = [];
    if (goals.includes(goal)) {
      newGoals = goals.filter((item: string) => item !== goal);
    } else {
      newGoals = [...goals, goal];
    }

    setValue('goals', newGoals);
    setSelectedGoals(newGoals);
    trigger('goals');

    return newGoals;
  };
  return (
    <>
      <Controller
        name="goals"
        control={control}
        rules={{
          validate: (value) => {
            if (value === undefined || value.length === 0) {
              return 'Selecione pelo menos uma opção';
            }
            return true;
          },
        }}
        render={({ field }) => (
          <S.Grid>
            {goalsList.map((goal, index) => (
              <S.GridItem key={index}>
                <Checkbox
                  className={S.checkboxStyles}
                  data-testid={`goal-list-input-${goal}`}
                  key={index}
                  label={goal}
                  defaultChecked={selectedGoals?.includes(goal)}
                  onCheckedChange={() => field.onChange(handleSelectGoal(goal))}
                />
              </S.GridItem>
            ))}
          </S.Grid>
        )}
      />
      {errors.goals && (
        <TextAreaErrorMessage>{errors.goals.message?.toString()}</TextAreaErrorMessage>
      )}
    </>
  );
};
