import { styled, Box } from 'styled-system/jsx';
import { cva, RecipeVariantProps } from 'styled-system/css';

const rootStyle = cva({
  base: {
    fontWeight: 'regular',
  },

  variants: {
    weight: {
      semibold: { fontWeight: 'semibold' },
      bold: { fontWeight: 'bold' },
    },
    family: {
      'dm-sans': { fontFamily: 'dm-sans' },
      'open-sans': { fontFamily: 'open-sans' },
    },
    variant: {
      h1: {
        fontSize: '3.5rem',
        lineHeight: '4.875rem',
      },
      h2: {
        fontSize: '3rem',
        lineHeight: '4.188rem',
      },
      h3: {
        fontSize: '2rem',
        lineHeight: '2.813rem',
      },
      h4: {
        fontSize: '1.5rem',
        lineHeight: '2.125rem',
      },
      h5: {
        fontSize: '1.25rem',
        lineHeight: '1.75rem',
      },
      menu: {
        fontSize: '1rem',
        lineHeight: '1.375rem',
      },
      body1: {
        fontSize: '1.125rem',
        lineHeight: '1.563rem',
      },
      body2: {
        fontSize: '1rem',
        lineHeight: '1.375rem',
      },
      body3: {
        fontSize: '0.875rem',
        lineHeight: '1.125rem',
      },
      body4: {
        fontSize: '0.625rem',
        lineHeight: '0.875rem',
      },
      quote: {
        fontSize: '1rem',
        lineHeight: '1.375rem',
        fontStyle: 'italic',
      },
      subtitle: {
        fontSize: '0.75rem',
        lineHeight: '1.25rem',
      },
    },
  },

  compoundVariants: [
    {
      variant: ['h1', 'h2', 'h3', 'h4', 'h5', 'menu'],
      css: {
        fontFamily: 'dm-sans',
      },
    },
    {
      variant: ['body1', 'body2', 'body3', 'body4', 'quote', 'subtitle'],
      css: {
        fontFamily: 'open-sans',
      },
    },
  ],
});

export const Root = styled(Box, rootStyle);

export type TextVariants = RecipeVariantProps<typeof rootStyle>;
