import {
  styled,
  pxToRem,
  Button as ButtonDefault,
  Input as InputDefault,
  Typography,
  Flex,
} from '@genialcare/atipico-react';
import { Wrapper as WrapperDefault } from 'components/Wrapper';
import { ScrollBox } from 'components/ScrollBox';

export const Wrapper = styled('div', {
  position: 'relative',
});

export const Input = styled(InputDefault, {
  width: '100%',
  paddingInlineEnd: `${pxToRem(105)} !important`,
});

export const Button = styled(ButtonDefault, {
  minWidth: 'fit-content',
  maxWidth: 'fit-content',
  height: pxToRem(40),
});

export const ListWrapper = styled(WrapperDefault, {
  marginTop: pxToRem(12),
  padding: pxToRem(16),
});

export const Title = styled(Typography, {
  paddingBottom: pxToRem(8),
  marginBottom: pxToRem(8),
  borderBottom: '1px solid #F1F1F1',
});

export const List = styled(ScrollBox, {});

export const Item = styled(Flex, {
  padding: pxToRem(8),
  borderRadius: pxToRem(8),
  transition: 'all .3s ease-in-out',
  '&:hover': {
    backgroundColor: '#FAFAFA',
  },
});

export const Text = styled(Typography, {
  fontSize: `${pxToRem(14)} !important`,
  lineHeight: `${pxToRem(21)} !important`,
});

export const CloseButton = styled('button', {
  backgroundColor: '$red500',
  cursor: 'pointer',
  border: 'none',
  outline: 'none',
  borderRadius: '50%',
  padding: 0,
  width: pxToRem(26),
  height: pxToRem(26),
  display: 'grid',
  placeItems: 'center',
  '&[disabled]': {
    cursor: 'not-allowed',
  },
});
