import { styled, pxToRem, Flex } from '@genialcare/atipico-react';

export const Root = styled(Flex, {
  width: 'auto',
  gap: pxToRem(8),
});

export const Item = styled('div', {
  cursor: 'pointer',
  padding: `${pxToRem(6)} ${pxToRem(12)}`,
  height: 'auto',
  width: 'fit-content',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: 'auto',
  marginRight: pxToRem(10),
  gap: pxToRem(8),
  borderRadius: pxToRem(40),
  backgroundColor: '$neutral100',
  color: '$neutral500',
  fontSize: pxToRem(14),
});
