import { css } from 'styled-system/css';
import { Grid as GridDefault, GridItem as GridItemDefault, styled } from 'styled-system/jsx';

export const checkboxStyles = css({
  height: 'fit-content',

  '& > label > button': {
    h: '100%',

    '& span': {
      fontSize: '14px',
    },
  },

  lg: {
    w: '100%',
  },
});

export const Grid = styled(GridDefault, {
  base: {
    gridTemplateColumns: '1',
    columnGap: '28px',
    gridGap: '16px',
    marginTop: '16px',
    md: {
      gridTemplateColumns: '2',
    },

    lg: {
      gridTemplateColumns: '3',
    },
  },
});

export const GridItem = styled(GridItemDefault, {
  base: {
    '& > div:first-child': {
      h: '100%',
      display: 'block',
    },
  },
});
