import { styled, pxToRem } from '@genialcare/atipico-react';

export const Root = styled('div', {
  maxHeight: pxToRem(750),
  overflowY: 'scroll',
  overflowX: 'hidden',
  '&::-webkit-scrollbar': {
    width: pxToRem(6),
  },
  '&::-webkit-scrollbar-thumb': {
    background: '$neutral200',
    borderRadius: pxToRem(4),
  },
});
