import { CSS, styled, pxToRem, Flex, InterfaceSearch } from '@genialcare/atipico-react';

export const iconContainerStyle: CSS = {
  pointerEvents: 'none',
};

export const Wrapper = styled(Flex, {
  position: 'relative',
});

export const SearchIcon = styled(InterfaceSearch, {
  width: pxToRem(25),
  height: pxToRem(25),
  fill: '$neutral400',
});

export const SearchContainer = styled(Flex, {
  width: '100%',
  gap: pxToRem(8),
  position: 'absolute',
  left: 0,
  top: pxToRem(65),
  padding: pxToRem(12),
  maxHeight: pxToRem(280),
  overflowY: 'auto',
  scrollBehavior: 'smooth',
  border: `${pxToRem(1)} solid $neutral200`,
  borderRadius: pxToRem(8),
  backgroundColor: '$neutral0',
  zIndex: 5,
});

export const SearchItem = styled(Flex, {
  padding: `${pxToRem(14)} ${pxToRem(12)}`,
  borderRadius: pxToRem(8),
  backgroundColor: '$neutral0',
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '$neutral100',
  },
});
