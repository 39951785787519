import React, { useEffect, useState } from 'react';
import { Flex } from '@genialcare/atipico-react';
import { InputSearch, InputSearchProps } from '../InputSearch';
import { ListItem } from './ListItem';
import _ from 'lodash';

export interface InputSearchWithListProps<T> extends InputSearchProps<T> {
  defaultItemsSelected?: T[];
  onChangeList?: (items: T[]) => void;
}

const InputSearchWithListInner = <T,>(props: InputSearchWithListProps<T>) => {
  const { defaultItemsSelected = [], onChangeList, ...inputProps } = props;
  const { onDisplayValue, css, isDisabled } = props;
  const [itemsSelected, setItemsSelected] = useState<T[]>(defaultItemsSelected);

  useEffect(() => {
    if (defaultItemsSelected?.length) {
      onChangeList?.(defaultItemsSelected);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectItem = (item: T) => {
    const newItemsSelected = _.uniqWith([...itemsSelected, item], _.isEqual);
    onChangeList?.(newItemsSelected);
    setItemsSelected(newItemsSelected);
  };

  const removeItem = (itemToRemove: T) => {
    const newItemsSelected = itemsSelected.filter((item) => !_.isEqual(item, itemToRemove));

    onChangeList?.(newItemsSelected);
    setItemsSelected(newItemsSelected);
  };

  return (
    <Flex direction="column" css={css}>
      <InputSearch cleanInputAfterSelect onSelectItem={handleSelectItem} {...inputProps} />
      <Flex display="flex" direction="row" css={{ width: '100%', marginTop: '20px' }}>
        {itemsSelected.map((item, index) => (
          <ListItem
            key={`input-selected-${index}`}
            onClose={removeItem}
            onDisplayValue={onDisplayValue}
            item={item}
            isDisabled={isDisabled}
          />
        ))}
      </Flex>
    </Flex>
  );
};

/* eslint-disable  @typescript-eslint/no-explicit-any */
/* eslint-disable  @typescript-eslint/no-unused-vars */
export const InputSearchWithList = React.forwardRef<
  HTMLInputElement,
  InputSearchWithListProps<any>
>((props, ref) => <InputSearchWithListInner {...props} />);
