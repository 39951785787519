import React, { PropsWithChildren } from 'react';
import * as S from './ErrorMessage.styles';

export const ErrorMessage = React.forwardRef<HTMLSpanElement, PropsWithChildren>(
  ({ children }, ref) => {
    if (!children) return null;

    return <S.Root ref={ref}>{children}</S.Root>;
  },
);
