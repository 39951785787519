import React from 'react';

import { Typography } from '@genialcare/atipico-react';

type QuestionLabelProps = {
  label: string;
  mandatory: boolean;
};

const mandatoryMark = (mandatory: boolean) => (mandatory ? '*' : '');

const buildLabel = (label: string, mandatory: boolean) => `${label}${mandatoryMark(mandatory)}`;

export const QuestionLabel: React.FC<QuestionLabelProps> = ({ label, mandatory, ...rest }) => (
  <Typography variant="body2" color="neutral500" weight="bold" {...rest}>
    {buildLabel(label, mandatory)}
  </Typography>
);
