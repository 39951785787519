import React from 'react';
import * as S from './ListItem.styles';

export type ListItem<T> = {
  item: T;
  onDisplayValue: (item: T) => string;
  onClose?: (item: T) => void;
  isDisabled?: boolean;
};

export const ListItem = <T,>(props: ListItem<T>) => {
  const { onClose, item, onDisplayValue, isDisabled = false } = props;
  const itemText = onDisplayValue(props.item);
  return (
    <S.Root>
      <S.Item data-testid={`input-selected-${itemText}`}>
        {itemText}
        {!isDisabled && (
          <div data-testid={`remove-selected-item-${itemText}`} onClick={() => onClose?.(item)}>
            x
          </div>
        )}
      </S.Item>
    </S.Root>
  );
};
