import React, { lazy } from 'react';
import { Route, Outlet, Navigate, Routes as ReactRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Loading } from 'components/Loading';
import { AuthenticatedUserContainer } from 'components/AuthenticatedUserContainer';
import { Details } from 'pages/ParentalTraining/Note';
import List from 'pages/ParentalTraining/Note/List/List';
import { SessionNotes } from 'pages/SessionNotes';
import { AssessementsSessionType } from 'types';

const Welcome = lazy(() => import('pages/Welcome').then((module) => ({ default: module.Welcome })));
const Home = lazy(() => import('pages/Home').then((module) => ({ default: module.Home })));
const Settings = lazy(() =>
  import('pages/Settings').then((module) => ({ default: module.Settings })),
);
const ClinicalCaseLayout = lazy(() =>
  import('pages/ClinicalCaseLayout').then((module) => ({ default: module.ClinicalCaseLayout })),
);
const ClinicalCase = lazy(() =>
  import('pages/ClinicalCase').then((module) => ({ default: module.ClinicalCase })),
);
const Transition = lazy(() =>
  import('pages/Transition').then((module) => ({
    default: module.Transition,
  })),
);
const TransitionForm = lazy(() =>
  import('pages/TransitionForm').then((module) => ({
    default: module.TransitionForm,
  })),
);
const ObservableLightForm = lazy(() =>
  import('pages/ObservableLight').then((module) => ({
    default: module.ObservableLightForm,
  })),
);
const TypeformFormResponse = lazy(() =>
  import('pages/TypeformFormResponse').then((module) => ({
    default: module.TypeformFormResponse,
  })),
);
const Assessment = lazy(() =>
  import('pages/Assessments').then((module) => ({ default: module.Assessments })),
);

const VinelandReports = lazy(() =>
  import('pages/Assessments/VinelandReports').then((module) => ({
    default: module.VinelandReports,
  })),
);

const Reports = lazy(() =>
  import('pages/Assessments/Reports').then((module) => ({
    default: module.Reports,
  })),
);
const DemandsHome = lazy(() =>
  import('pages/Demands').then((module) => ({ default: module.Home })),
);
const NewDemand = lazy(() =>
  import('pages/Demands').then((module) => ({ default: module.NewDemand })),
);
const Interventions = lazy(() =>
  import('pages/SessionNotes').then((module) => ({ default: module.SessionNotes })),
);
const InterventionTweets = lazy(() =>
  import('pages/SessionNotes').then((module) => ({ default: module.Tweets })),
);
const InterventionTweetsSuccess = lazy(() =>
  import('pages/SessionNotes').then((module) => ({ default: module.TweetsSuccess })),
);

const SessionsHome = lazy(() =>
  import('pages/Sessions').then((module) => ({ default: module.Home })),
);
const SessionDetails = lazy(() =>
  import('pages/Sessions').then((module) => ({ default: module.Details })),
);
const SessionPreparation = lazy(() =>
  import('pages/Sessions').then((module) => ({ default: module.Preparation })),
);
const SessionComplete = lazy(() =>
  import('pages/Sessions').then((module) => ({ default: module.Complete })),
);
const SessionNote = lazy(() =>
  import('pages/Sessions').then((module) => ({ default: module.Note })),
);

const SessionEvolutionCheck = lazy(() =>
  import('pages/Sessions').then((module) => ({ default: module.EvolutionCheck })),
);

const ParentalTrainingNote = lazy(() =>
  import('pages/ParentalTraining/Note/New').then((module) => ({ default: module.Form })),
);

const ClinicalGuidancesHome = lazy(() =>
  import('pages/ClinicalGuidances').then((module) => ({ default: module.Home })),
);
const CoverPage = lazy(() =>
  import('pages/CoverPage').then((module) => ({
    default: module.CoverPage,
  })),
);
const ClinicalCasePreferencesEdit = lazy(() =>
  import('pages/CoverPage/ClinicalPreferences/Preferences/ClinicalCasePreferencesEdit').then(
    (module) => ({
      default: module.ClinicalCasePreferencesEdit,
    }),
  ),
);
const ClinicalCaseWorkloadsEdit = lazy(() =>
  import('pages/CoverPage/Workloads/Workloads/ClinicalCaseWorkloadsEdit').then((module) => ({
    default: module.ClinicalCaseWorkloadsEdit,
  })),
);
const ClinicalGuidanceNewDiscussion = lazy(() =>
  import('pages/ClinicalGuidances').then((module) => ({ default: module.NewDiscussion })),
);
const ClinicalGuidanceEditDiscussion = lazy(() =>
  import('pages/ClinicalGuidances').then((module) => ({ default: module.EditDiscussion })),
);
const ClinicalGuidanceNewNotice = lazy(() =>
  import('pages/ClinicalGuidances').then((module) => ({ default: module.NewNotice })),
);
const ClinicalGuidanceEditNotice = lazy(() =>
  import('pages/ClinicalGuidances').then((module) => ({ default: module.EditNotice })),
);
const ClinicalGuidanceRegistryDetails = lazy(() =>
  import('pages/ClinicalGuidances').then((module) => ({ default: module.RegistryDetails })),
);
const ComplexityScores = lazy(() =>
  import('pages/ComplexityScores').then((module) => ({ default: module.ComplexityScores })),
);
const PEI = lazy(() => import('pages/PEI').then((module) => ({ default: module.Home })));

const PEIObjective = lazy(() =>
  import('pages/PEI').then((module) => ({ default: module.Objective })),
);
const PEIDisciplineNew = lazy(() =>
  import('pages/PEI').then((module) => ({ default: module.PEIDisciplineNew })),
);
const PEIDisciplineEdit = lazy(() =>
  import('pages/PEI').then((module) => ({ default: module.PEIDisciplineEdit })),
);
const PEINew = lazy(() => import('pages/PEI').then((module) => ({ default: module.New })));
const PEIUpdate = lazy(() => import('pages/PEI').then((module) => ({ default: module.Update })));
const PEIObjectiveClone = lazy(() =>
  import('pages/PEI').then((module) => ({ default: module.Clone })),
);
const SupportLinks = lazy(() =>
  import('pages/SupportLinks/Home').then((module) => ({ default: module.Home })),
);
const CreateSupportLink = lazy(() =>
  import('pages/SupportLinks/New').then((module) => ({ default: module.New })),
);
const CreateClinicalCaseFile = lazy(() =>
  import('pages/SupportLinks/ClinicalCaseFiles/New').then((module) => ({
    default: module.New,
  })),
);
const ClinicalCaseFileDetails = lazy(() =>
  import('pages/SupportLinks/ClinicalCaseFiles/Details').then((module) => ({
    default: module.Details,
  })),
);
const UpdateClinicalCaseFile = lazy(() =>
  import('pages/SupportLinks/ClinicalCaseFiles/Edit').then((module) => ({
    default: module.Edit,
  })),
);
const CreateClinicalCaseTask = lazy(() =>
  import('pages/Task').then((module) => ({ default: module.NewClinicalCaseTask })),
);
const CreateGuidanceTask = lazy(() =>
  import('pages/Task').then((module) => ({ default: module.NewGuidanceTask })),
);
const NewRegistryTask = lazy(() =>
  import('pages/Task/Registry/New').then((module) => ({ default: module.NewRegistryTask })),
);
const NewPersonalTask = lazy(() =>
  import('pages/Task/Personal/New').then((module) => ({ default: module.NewPersonalTask })),
);
const UserTasks = lazy(() =>
  import('pages/Users/Tasks').then((module) => ({ default: module.Tasks })),
);
const UserPendencies = lazy(() =>
  import('pages/Users/Pendencies').then((module) => ({ default: module.Pendencies })),
);
const UserMarketplace = lazy(() =>
  import('pages/Users/Marketplace').then((module) => ({ default: module.Marketplace })),
);

const NotFound = lazy(() =>
  import('pages/NotFound').then((module) => ({
    default: module.NotFound,
  })),
);
const UserSessions = lazy(() =>
  import('pages/Users').then((module) => ({
    default: module.SessionList,
  })),
);
const SessionPlanningHome = lazy(() =>
  import('pages/Users/SessionPlanning').then((module) => ({
    default: module.Home,
  })),
);
const SessionPlanningDetails = lazy(() =>
  import('pages/Users/SessionPlanning').then((module) => ({
    default: module.Details,
  })),
);
const PlanningHome = lazy(() =>
  import('pages/Users/Planning').then((module) => ({
    default: module.Home,
  })),
);
const PlanningDetails = lazy(() =>
  import('pages/Users/Planning').then((module) => ({
    default: module.Details,
  })),
);
const FamilySupportCenterHome = lazy(() =>
  import('pages/Users/FamilySupportCenter').then((module) => ({
    default: module.Home,
  })),
);
const FamilySupportCenterDetails = lazy(() =>
  import('pages/Users/FamilySupportCenter').then((module) => ({
    default: module.Details,
  })),
);
const VinelandReportsHome = lazy(() =>
  import('pages/VinelandReports').then((module) => ({
    default: module.Home,
  })),
);
const VinelandItemScores = lazy(() =>
  import('pages/VinelandReports').then((module) => ({
    default: module.ItemScores,
  })),
);
const VinelandItemScoresRevision = lazy(() =>
  import('pages/VinelandReports').then((module) => ({
    default: module.ItemScoresRevision,
  })),
);
const VinelandItemScoresSuccess = lazy(() =>
  import('pages/VinelandReports').then((module) => ({
    default: module.Success,
  })),
);
const IndirectAssessmentNote = lazy(() =>
  import('pages/Assessments').then((module) => ({
    default: module.IndirectNote,
  })),
);

const DirectAssessmentNote = lazy(() =>
  import('pages/Assessments').then((module) => ({
    default: module.DirectNote,
  })),
);

const FeedbackAssessmentNote = lazy(() =>
  import('pages/Assessments').then((module) => ({
    default: module.FeedbackNote,
  })),
);

const ClinicalGuidancePlanningsHome = lazy(() =>
  import('pages/Users/ClinicalGuidancePlanning').then((module) => ({
    default: module.Home,
  })),
);

const ClinicalGuidancePlanningsEdit = lazy(() =>
  import('pages/Users/ClinicalGuidancePlanning').then((module) => ({
    default: module.Details,
  })),
);

export const ProtectedLayout = () => {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return (
    <AuthenticatedUserContainer>
      <Outlet />
    </AuthenticatedUserContainer>
  );
};

const AssessmentsRoutes = (
  <>
    <Route path="assessments/evaluative-data" element={<Assessment />} />
    <Route path="assessments/typeform-form-responses" element={<TypeformFormResponse />} />
    <Route path="assessments/transitions" element={<Transition />} />
    <Route path="assessments/transitions/form" element={<TransitionForm />} />
    <Route
      path="assessments/notes"
      element={<SessionNotes sessionTypes={AssessementsSessionType} />}
    />
    <Route path="assessments/vineland-reports" element={<VinelandReports />} />
    <Route path="assessments/reports" element={<Reports />} />
    <Route
      path="assessments/notes/:sessionId"
      element={<SessionNotes sessionTypes={AssessementsSessionType} />}
    />
    <Route path="assessments/vineland-reports" element={<VinelandReportsHome />} />

    <Route
      path="assessments/vineland-reports/:reportId/item-scores"
      element={<VinelandItemScores />}
    />
    <Route
      path="assessments/vineland-reports/:reportId/item-scores-revision"
      element={<VinelandItemScoresRevision />}
    />

    <Route
      path="assessments/vineland-reports/:reportId/item-scores-success"
      element={<VinelandItemScoresSuccess />}
    />
  </>
);

const InterventionsRoutes = (
  <>
    <Route path="interventions" element={<Interventions />} />
    <Route path="interventions/:sessionId" element={<Interventions />} />
    <Route path="interventions/:interventionId/tweets" element={<InterventionTweets />} />
    <Route
      path="interventions/:interventionId/tweets/success"
      element={<InterventionTweetsSuccess />}
    />
  </>
);
const ClinicalGuidanceRoutes = (
  <>
    <Route path="clinical-guidances" element={<ClinicalGuidancesHome />} />
    <Route path="clinical-guidances/discussion/new" element={<ClinicalGuidanceNewDiscussion />} />
    <Route
      path="clinical-guidances/:clinicalGuidanceId/discussion/edit"
      element={<ClinicalGuidanceEditDiscussion />}
    />
    <Route path="clinical-guidances/notice/new" element={<ClinicalGuidanceNewNotice />} />
    <Route
      path="clinical-guidances/:clinicalGuidanceId/notice/edit"
      element={<ClinicalGuidanceEditNotice />}
    />
  </>
);
const TaskRoutes = (
  <>
    <Route path="overview/tasks/new" element={<CreateClinicalCaseTask />} />
    <Route
      path="clinical-guidances/:clinicalGuidanceId/tasks/new"
      element={<CreateGuidanceTask />}
    />
    <Route
      path="/panel/clinical-cases/:id/overview/registries/:registryId/tasks/new"
      element={<NewRegistryTask />}
    />
    <Route
      path="/panel/clinical-cases/:id/overview/registries/:registryId/observable-light-form"
      element={<ObservableLightForm />}
    />
  </>
);
const DemandRoutes = (
  <>
    <Route path="demands">
      <Route index element={<DemandsHome />} />
      <Route path=":demandId" element={<DemandsHome />} />
      <Route path="new" element={<NewDemand />} />
    </Route>
  </>
);
const UserRoutes = (
  <Route path="users">
    <Route path="sessions">
      <Route index element={<UserSessions />} />
    </Route>
    <Route path="tasks" element={<UserTasks />} />
    <Route path="pendencies" element={<UserPendencies />} />
    <Route path="marketplace" element={<UserMarketplace />} />
    <Route path="session-planning">
      <Route index element={<SessionPlanningHome />} />
      <Route path=":sessionId" element={<SessionPlanningDetails />} />
    </Route>
    <Route path="planning">
      <Route index element={<PlanningHome />} />
      <Route path=":planningId" element={<PlanningDetails />} />
    </Route>
    <Route path="clinical-guidance-planning">
      <Route index element={<ClinicalGuidancePlanningsHome />} />
      <Route path=":clinicalGuidancePlanningId/edit" element={<ClinicalGuidancePlanningsEdit />} />
    </Route>
    <Route
      path="clinical-case/:clinicalCaseId/planning/:planningId"
      element={<PlanningDetails />}
    />
    <Route path="family-support-center">
      <Route index element={<FamilySupportCenterHome />} />
      <Route path=":conversationId/:clinicalCaseId" element={<FamilySupportCenterDetails />} />
    </Route>
  </Route>
);
export const Routes = () => (
  <ReactRouter>
    <Route path="/panel" element={<ProtectedLayout />}>
      <Route path="/panel/home" element={<Home />} />
      <Route path="/panel/user/tasks/new" element={<NewPersonalTask />} />
      <Route path="/panel/clinical-cases/:id" element={<ClinicalCaseLayout />}>
        <Route path="details">
          {/* deprecated */}
          <Route index element={<CoverPage />} />
        </Route>
        <Route path="cover-page">
          <Route index element={<CoverPage />} />
        </Route>
        <Route path="preferences/:preferencesId/edit">
          <Route index element={<ClinicalCasePreferencesEdit />} />
        </Route>
        <Route path="workloads/edit">
          <Route index element={<ClinicalCaseWorkloadsEdit />} />
        </Route>
        <Route path="overview">
          <Route index element={<ClinicalCase />} />
          <Route path="registries/:registryId" element={<ClinicalGuidanceRegistryDetails />} />
        </Route>
        <Route path="support-links" element={<SupportLinks />} />
        <Route path="support-links/new" element={<CreateSupportLink />} />
        <Route path="files/new" element={<CreateClinicalCaseFile />} />
        <Route path="files/:fileId/details" element={<ClinicalCaseFileDetails />} />
        <Route path="files/:fileId/edit" element={<UpdateClinicalCaseFile />} />
        <Route path="pei">
          <Route index element={<PEI />} />
          <Route path="objective" element={<PEINew />} />
          <Route path="objective/:objectiveId" element={<PEIObjective />} />
          <Route path="objective/:objectiveId/edit" element={<PEIUpdate />} />
          <Route path="objective/:objectiveId/clone" element={<PEIObjectiveClone />} />
          <Route path="objective/:objectiveId/program/new" element={<PEIDisciplineNew />} />
          <Route
            path="objective/:objectiveId/program/:programId/edit"
            element={<PEIDisciplineEdit />}
          />
        </Route>
        <Route path="sessions">
          <Route index element={<SessionsHome />} />
          <Route path=":sessionId">
            <Route path="details" element={<SessionDetails />} />
            <Route path="preparation" element={<SessionPreparation />} />
            <Route path="complete" element={<SessionComplete />} />
            <Route path="note" element={<SessionNote />} />
            <Route path="indirect-note" element={<IndirectAssessmentNote />} />
            <Route path="direct-note" element={<DirectAssessmentNote />} />
            <Route path="feedback-note" element={<FeedbackAssessmentNote />} />
            <Route path="evolution-check" element={<SessionEvolutionCheck />} />
            <Route path="parental-training-note">
              <Route index element={<ParentalTrainingNote />} />
              <Route path=":parentalTrainingNoteId">
                <Route path="details" element={<Details />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="demands">
          <Route index element={<DemandsHome />} />
        </Route>
        <Route path="parental-training">
          <Route index element={<List />} />
          <Route path=":sessionId" element={<List />} />
        </Route>
        <Route path="complexity-scores" element={<ComplexityScores />} />
        {DemandRoutes}
        {AssessmentsRoutes}
        {InterventionsRoutes}
        {ClinicalGuidanceRoutes}
        {TaskRoutes}
      </Route>
      {UserRoutes}
      <Route path="/panel/settings" element={<Settings />} />
    </Route>
    <Route path="/" element={<Welcome />} />
    <Route path="/not-found" element={<NotFound />} />
    <Route path="*" element={<Navigate to="/not-found" replace />} />
  </ReactRouter>
);
