import { css } from 'styled-system/css';
import { Typography, pxToRem, styled } from '@genialcare/atipico-react';
import { Wrapper } from 'components/Wrapper';
import { Tooltip as TooltipDefault } from 'components/Tooltip';

export const alertDialogStyle = css({
  '& a': {
    color: 'purple.500',
  },
});

export const Root = styled(Wrapper, {
  marginTop: pxToRem(42),
});

export const FieldGroup = styled('div', {
  textAlign: 'left',
  marginBottom: pxToRem(32),
});

export const Title = styled(Typography, {
  marginBottom: pxToRem(8),
});

export const Subtitle = styled(Typography, {
  marginBottom: pxToRem(16),
});

export const Tooltip = styled(TooltipDefault, {
  marginLeft: pxToRem(4),
});
