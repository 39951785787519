import React, { useRef, useState, useDeferredValue } from 'react';
import { matchSorter } from 'match-sorter';
import {
  CSS,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@genialcare/atipico-react';
import { useMergeRefs } from 'hooks/useMergeRefs';
import { useOnClickOutside } from 'hooks/useOnClickOutside';
import * as S from './InputSearch.styles';

export interface InputSearchProps<T> {
  css?: CSS;
  items?: T[];
  iconPlacement?: 'left' | 'right';
  isInvalid?: boolean;
  showListOnFocus?: boolean;
  cleanInputAfterSelect?: boolean;
  placeholder?: string;
  defaultValue?: string;
  startSearchLength?: number;
  searchKeys: string[];
  isDisabled?: boolean;
  isHidden?: boolean;
  renderItem: (item: T) => React.ReactElement;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectItem?: (item: T) => void;
  onDisplayValue: (item: T) => string;
}

const renderIcon = (
  Container: React.FunctionComponent<{
    children: React.ReactNode;
    css?: CSS;
  }>,
  visible: boolean,
) => {
  if (visible) {
    return (
      <Container css={S.iconContainerStyle}>
        <S.SearchIcon />
      </Container>
    );
  }

  return <></>;
};

function InputSearchInner<T>(
  props: InputSearchProps<T>,
  ref: React.ForwardedRef<HTMLInputElement>,
) {
  const {
    items = [],
    isInvalid = false,
    showListOnFocus = false,
    startSearchLength = 4,
    defaultValue = '',
    cleanInputAfterSelect = false,
    isDisabled = false,
    isHidden = false,
    iconPlacement,
    searchKeys,
    placeholder,
    renderItem,
    onBlur,
    onChange,
    onSelectItem,
    onDisplayValue,
    ...inputProps
  } = props;
  const [showListItems, setShowListItems] = useState(false);
  const [value, setValue] = useState(defaultValue);
  const deferredValue = useDeferredValue(value);
  const inputRef = useRef<HTMLInputElement>();
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const refs = useMergeRefs(inputRef, ref as React.RefObject<HTMLInputElement>);

  useOnClickOutside(wrapperRef, () => {
    setShowListItems(false);
  });

  const handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    setShowListItems(true);
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    onChange?.(event);
    setValue(event.target.value);
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.preventDefault();
    onBlur?.(event);
  };

  const handleOnSelect = (item: T) => () => {
    const inputValue = onDisplayValue(item);

    onSelectItem?.(item);
    setShowListItems(false);

    if (inputRef.current) {
      if (cleanInputAfterSelect) {
        inputRef.current.value = '';
        setValue(defaultValue);
      } else {
        inputRef.current.value = inputValue;
        setValue(inputValue);
      }
    }
  };

  const notStartSearch = deferredValue.length < startSearchLength;
  const list = notStartSearch && !showListOnFocus ? [] : items;
  const filteredList = matchSorter(list, deferredValue, {
    keys: searchKeys,
    threshold: matchSorter.rankings.CONTAINS,
    sorter: (data) => data,
  });

  const showList = showListItems && Boolean(filteredList.length);

  const { css } = inputProps;

  return (
    <S.Wrapper ref={wrapperRef}>
      <InputGroup>
        {renderIcon(InputLeftElement, iconPlacement === 'left')}

        <Input
          {...inputProps}
          ref={refs}
          defaultValue={value}
          isInvalid={isInvalid}
          placeholder={placeholder}
          onFocus={handleOnFocus}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          isDisabled={isDisabled}
          hidden={isHidden}
        />

        {renderIcon(InputRightElement, iconPlacement === 'right')}
      </InputGroup>

      {showList && (
        <S.SearchContainer direction="column" data-input-search-items css={css}>
          {filteredList.map((item, index) => (
            <S.SearchItem key={`input-search-item-${index}`} onMouseDown={handleOnSelect(item)}>
              {renderItem(item)}
            </S.SearchItem>
          ))}
        </S.SearchContainer>
      )}
    </S.Wrapper>
  );
}

export const InputSearch = React.forwardRef(InputSearchInner) as <T>(
  props: InputSearchProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof InputSearchInner>;
