export type FormAreaData = {
  id: string;
  title: string;
  shortTitle: string;
  name: string;
  createdAt?: string;
  updatedAt?: string;
  formGroupQuestions: FormGroupQuestion[];
};

export type FormGroupQuestion = {
  id: string;
  title: string;
  description?: string;
  formQuestions: FormQuestion[];
};

export type FormQuestion = {
  id: string;
  number: string;
  label: string;
  description?: string;
  required: boolean;
  formAnswers: FormAnswer[];
};

export type FormAnswer = {
  id: string;
  label: string;
  weight: string;
};

export type FormRegistration = {
  formAreaId: string;
  formQuestionAnswers: FormQuestionAnswer[];
};

export type FormQuestionAnswer = {
  formQuestionId: string;
  formAnswerId: string;
};

export enum CLINICIAN_SENIORITY {
  T1 = 'T1',
  T2 = 'T2',
  T3 = 'T3',
}
