import React, { PropsWithChildren, useState } from 'react';
import { CSS, Typography } from '@genialcare/atipico-react';
import { Tooltip } from 'components/Tooltip';
import { AlertDialog, AlertDialogBody, AlertDialogTitle } from 'components/AlertDialog';
import { useDevice } from 'hooks/useDevice';
import * as S from './FormField.styles';

export type ToolTipProps = {
  title: string;
  text: string | React.ReactNode;
};

export type FormFieldProps = PropsWithChildren & {
  css?: CSS;
  className?: string;
  title: string;
  subtitle?: string;
  tooltip?: ToolTipProps;
};

export const FormField: React.FC<FormFieldProps> = ({
  children,
  title,
  subtitle,
  css,
  className,
  tooltip,
}) => {
  const [openTip, setOpenTip] = useState<boolean>(false);
  const { isBigScreen } = useDevice();

  return (
    <S.FieldGroup css={css} className={className}>
      {tooltip && (
        <AlertDialog
          open={openTip && !isBigScreen}
          onOpenChange={setOpenTip}
          className={S.alertDialogStyle}
          closable
        >
          <AlertDialogTitle>{tooltip.title}</AlertDialogTitle>
          <AlertDialogBody>
            <Typography variant="body2">{tooltip.text}</Typography>
          </AlertDialogBody>
        </AlertDialog>
      )}
      <S.Title variant="h5" weight="bold">
        {title}
        {tooltip && (
          <S.Tooltip isOpen={openTip && isBigScreen} onOpenChange={setOpenTip}>
            <Tooltip.Text variant="title">{tooltip.title}</Tooltip.Text>
            <Tooltip.Text>{tooltip.text}</Tooltip.Text>
          </S.Tooltip>
        )}
      </S.Title>
      {subtitle && (
        <S.Subtitle variant="body2" color="neutral400">
          {subtitle}
        </S.Subtitle>
      )}
      {children}
    </S.FieldGroup>
  );
};
